<template>
  <NuxtLink
    :to="disabled ? undefined : url"
    :target="disabled ? undefined : target"
    :aria-label="ariaLabel"
    :event="disabled ? undefined : 'click'"
    :class="{ [$style.not_link]: !url || disabled }"
    @click="emits('click')"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
import { computed } from "vue"
import { type AtomsConsumerLinkProps } from "@tential/consumer-component/type"

const props = withDefaults(defineProps<AtomsConsumerLinkProps>(), {
  url: "",
  ariaLabel: "",
  blank: false,
  disabled: false,
})

const emits = defineEmits<{
  (e: "click"): void
}>()

// blankがtrueか外部リンクだった場合、新しいタブで開く
const target = computed(() => (props.blank || isExternalLink.value ? "_blank" : ""))

const isExternalLink = computed(() => {
  if (typeof props.url !== "string") return false
  return props.url.match(/^https?:\/\//) !== null
})
</script>

<style scoped module lang="scss">
.not_link {
  cursor: initial;
}
</style>
